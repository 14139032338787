@import '../../variables';

nav a {
  font-family: $font-sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.desktop nav {
  a {
    display: inline-block;
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border-bottom: $border;
      border-top: $border;
      color: $color-secondary;
      padding: 1rem 0;
    }
  }
}

.mobile nav {
  margin-top: 3rem;

  a {
    color: $color-white;
    display: block;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 2rem 0;
    text-align: left;

    &:hover {
      color: $color-sandy;
    }
  }
}

@media screen and (max-width: 1000px) {
  .desktop nav a {
    padding: 0.5rem;
  }
}
