@import 'variables';

html {
    font-size: 10px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    background-color: $color-white;
    color: $color-primary;
    font-family: $font-serif;
    font-size: 1.8rem;
    font-weight: 400;
    width: 100%;
}

h1,
h2 {
    font-weight: 700;
    width: 100%;
}

h1 {
    font-size: 4.8rem;
    margin-bottom: 2rem;
}

h2 {
    font-size: 3.4rem;
    margin-bottom: 1rem;
}

h3 {
    color: $color-primary;
    font-size: 1.3vw;
    letter-spacing: 2px;
    text-transform: uppercase;
}

h4 {
    color: $color-primary;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

p,
.p-block {
    line-height: 1.4;
    width: 100%;
}

p {
    margin-bottom: 1rem;
}

.p-block {
    margin-bottom: 2rem;

    h2 {
        line-height: 3.8rem;
    }
}

strong {
    font-weight: 700;
}

i {
    font-style: italic;
}

small {
    font-size: 80%;
}

ul {
    line-height: 2;
    list-style: disc;
    margin-bottom: 1rem;
    padding-left: 4rem;
}

ol {
    list-style-type: decimal;
    padding-left: 6rem;

    li {
        margin-bottom: 2rem;
    }
}

ol.alpha {
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 1;
    list-style-type: lower-alpha;

    p {
        font-size: 1.8rem;
        font-weight: 400;
    }
}

a {
    color: $color-primary;
    text-decoration: none;
    @include transition;

    &:hover {
        color: $color-secondary;
    }

    &:focus {
        outline: none;
    }
}

img {
    image-rendering: -webkit-optimize-contrast;
}

.arrow-link {
    height: 1.5rem;
    margin-left: 0.5rem;
    width: 1.5rem;
}

button,
.button {
    background: transparent;
    border: 1px solid $color-secondary;
    border-radius: 0.5rem;
    color: $color-grey;
    cursor: pointer;
    font-family: $font-sans-serif;
    font-size: 1.3rem;
    padding: 1.5rem 3rem;
    text-transform: uppercase;
    @include transition;

    &:hover {
        background: $color-secondary;
        color: $color-white;
    }
}

.clearfix {
    clear: both;
    height: 0;
}

form {
    width: 100%;
}

label {
    display: block;
    font-family: $font-sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

input,
select,
.select,
textarea {
    border: $border;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    padding: 1.5rem;
    width: 100%;
}

textarea {
    overflow-y: auto;
}

.select {
    height: 5.1rem;
    padding: 0;
    position: relative;
    @include flexbox();
    @include align-items(center);

    .select__control {
        border: 0;
        cursor: pointer;
        height: 100%;
        width: 100%;
    }

    .select__menu {
        left: 0;
    }

    .select__value-container {
        height: 100%;
    }

    .select__indicator-separator {
        display: none;
    }
}

.form-group {
    margin-bottom: 3rem;
}

.form-group-inline {
    width: 80%;
    @include flexbox();
    @include align-items(center);

    input,
    select,
    .select {
        width: 100%;
    }
}

.form-horizontal {
    @include flexbox();
    @include align-items(flex-end);
    @include flex-direction(column);

    .form-group {
        width: 100%;
        @include flexbox();
        @include align-items(center);
    }

    label {
        margin-bottom: 0;
        margin-right: 1rem;
        text-align: right;
        width: 20%;
    }

    input,
    select,
    .select,
    textarea {
        width: 80%;
    }

    .form-group-inline label {
        margin-left: 1rem;
        text-align: center;
    }
}

.img-responsive {
    display: block;
    height: auto;
    width: 100%;
}

.horizontal-line {
    border-bottom: $border;
    margin: 2rem 0;
    width: 100%;
}

.flex-block {
    width: 100%;
    @include flexbox();
}

.page {
    margin-top: 10rem;
    @include flexbox();
    @include flex-direction(column);
}

main,
.main {
    height: 100%;
    width: 100%;
}

.video-frame {
    min-height: 50vw;
    width: 800px;
    @media (orientation: landscape) {
        max-height: 90vh;
        min-height: 40vw;
    }
}
.hero-wrapper {
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -10rem;
    padding: 0;
    position: relative;
    width: 100%;
    @include flexbox();
    @include align-items(flex-start);
    @include flex-direction(column);
    @include justify-content(center);

    .recipe-link {
        background-color: rgba($color-primary, 0.3);
        bottom: 2rem;
        color: $color-white;
        display: block;
        font-family: $font-serif;
        font-size: 1.8rem;
        font-style: italic;
        line-height: 1.2;
        padding: 1rem;
        padding-right: 2rem;
        position: absolute;
        right: 0;
        text-align: right;
        text-transform: capitalize;
        @include transition;
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-end);

        .arrow-link {
            height: 2.5rem;
            margin-left: 1rem;
            width: 2.5rem;
        }

        small {
            display: block;
        }

        &:hover {
            color: $color-sandy;
        }
    }

    .hero-block {
        height: 100%;
        min-height: 33rem;
        padding-bottom: 4%;
        padding-left: 4%;
        width: 50%;

        h1,
        h2,
        h3,
        p {
            color: $color-white;
        }

        button,
        .button {
            border-color: $color-white;
            color: $color-white;

            &:hover {
                background: $color-white;
                color: $color-primary;
            }
        }

        .search-wrapper {
            background: linear-gradient(
                to right,
                rgba($color-sandy, 0.15) 50%,
                rgba($color-sandy, 0) 100%
            );
            border: 0;
            font-family: $font-sans-serif;
            margin: 3vw 0;

            input {
                color: $color-sandy;
                font-size: 1vw;
                padding: 1.3vw;

                &::placeholder {
                    color: $color-sandy;
                }
            }
        }
    }

    .promo {
        background-image: url('/images/promo.png');
        background-position: center center;
        background-size: cover;
        bottom: 0;
        font-family: $font-serif;
        font-size: 1.8rem;
        font-weight: 700;
        height: 15.6rem;
        left: 0;
        padding: 0 1.5rem;
        position: absolute;
        width: 22.9rem;
        @include flexbox();
        @include align-items(flex-end);
    }
}

.body-wrapper {
    padding: 2% 4%;
    width: 100%;
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
    @include flex-wrap(wrap);

    &.column {
        @include flex-direction(column);
    }

    &.column-narrow {
        margin: auto;
        max-width: 70vw;
    }

    .column-block-25,
    .column-block-50,
    .column-block-75 {
        @include flexbox();
        @include flex-direction(column);
    }

    .column-block-25 {
        width: 23%;
    }

    .column-block-50 {
        width: 48%;
    }

    .column-block-75 {
        width: 75%;
    }

    .block-title {
        font-family: $font-sans-serif;
        width: 100%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        a {
            font-size: 1.1vw;
            letter-spacing: 2px;
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);
        }
    }

    .featured-wrapper {
        @include flexbox();
        @include justify-content(space-between);
        @include flex-wrap(wrap);

        .featured-block {
            margin-top: 1.4rem;
            width: 49%;

            &.flavors {
                background-color: $color-light-grey;
                padding: 2rem;
                text-align: center;

                .flavor-image {
                    max-height: 18rem;
                    @include transition;
                }
            }

            &.recipes {
                .recipe-name {
                    background: linear-gradient(
                        rgba(0, 0, 0, 0) 30%,
                        rgba(0, 0, 0, 1) 100%
                    );
                }

                .recipe-image {
                    overflow: hidden;
                    position: relative;
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(center);

                    img {
                        @include transition;
                        object-fit: cover;
                        width: 100%;
                    }
                }
            }

            .flavor-name {
                color: $color-grey;
                font-family: $font-serif;
                font-weight: 700;
                font-size: 2rem;
                text-align: center;
                text-transform: capitalize;
                @include transition;
            }

            &:hover {
                .flavor-image,
                .recipe-image img {
                    @include transform(scale(1.1));
                }

                .recipe-image img {
                    filter: brightness(1.3);
                }

                .flavor-name {
                    color: $color-secondary;
                }
            }
        }
    }

    .recipe-name {
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        bottom: 0;
        color: $color-white;
        font-size: 1.8rem;
        font-weight: 700;
        left: 0;
        line-height: 1.3;
        min-height: 1rem;
        padding: 3rem 1rem 1rem;
        position: absolute;
        text-shadow: 0 0 1.5rem black;
        text-transform: capitalize;
        width: 100%;
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(flex-end);

        .cooking-time {
            @include flexbox();
            @include align-items(center);

            .recipe-clock {
                background-image: url('/images/timer.png');
                background-repeat: no-repeat;
                height: 14px;
                width: 23px;
            }
        }
    }

    &.filter {
        .column-block-25,
        .column-block-75 {
            @include flex-grow(1);
        }

        .column-block-25 {
            min-width: 22rem;
        }

        .filter-content {
            border-right: $border;
            padding-right: 2rem;
        }

        .result-content {
            padding-left: 2rem;
            @include flexbox();
            @include flex-direction(column);

            .load-more {
                margin: 0 auto;
                margin-top: 2rem;
                width: 15rem;
            }
        }
    }

    .filter-toggle {
        background: url('/images/filter.png') no-repeat center center;
        cursor: pointer;
        height: 48px;
        width: 48px;
    }

    .filter-collapse {
        display: none;
        margin-top: 2rem;

        &.show {
            display: block;
        }
    }

    .header {
        border-bottom: $border;
        font-family: $font-sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 2rem;
        padding-bottom: 0.5rem;
        text-transform: uppercase;
        width: 100%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);

        .flex-block {
            width: auto;
            @include align-items(center);
        }

        .clear-all {
            border: 0;
            color: $color-primary;
            font-family: $font-serif;
            font-size: 1.3rem;
            font-weight: 700;
            padding: 0;
            text-transform: none;
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);

            &:hover {
                background-color: transparent;
                color: $color-secondary;
            }
        }
    }

    .results-header {
        border-bottom: $border;
        color: $color-grey;
        display: block;
        padding-bottom: 0.5rem;
    }

    .sauce-container {
        margin-top: 2rem;
        @include flexbox();
        @include flex-wrap(wrap);
    }

    .table-container {
        margin-bottom: 2rem;

        .sause-table {
            color: $color-grey;
            font-size: 1.6rem;
            height: 3.5rem;
            @include flexbox();
            @include align-items(center);

            .label {
                font-family: $font-sans-serif;
                font-size: 1.2rem;
                letter-spacing: 2px;
            }

            :nth-child(odd) {
                height: 2rem;
            }

            .underline {
                border-bottom: 2px dotted $color-light-grey;
                margin: 0.5rem 0.5rem 0 0.5rem;
                @include flex-grow(1);
            }

            .info-list {
                list-style: none;
                padding: 0;

                li {
                    display: inline-block;
                    padding-left: 0.5rem;

                    &:first-child {
                        padding-left: 0;
                    }

                    &::after {
                        content: ',';
                    }

                    &:last-child::after {
                        content: none;
                    }
                }
            }

            .sause-link {
                color: $color-primary;
                cursor: pointer;
                text-align: right;
                @include flexbox();
                @include align-items(center);
                @include transition;

                &:hover {
                    color: $color-secondary;
                }
            }
        }
    }

    .loading {
        padding: 1rem;
        margin: 0;
        text-align: center;
    }
}

@media screen and (min-width: 769px) {
    .body-wrapper .featured-wrapper .featured-block {
        margin-top: 1vw;

        &.flavors {
            min-height: 17vw;
            overflow: hidden;
            padding: 1vw;

            .flavor-image {
                max-height: 12vw;
            }

            .flavor-name {
                font-size: 1.3vw;
            }
        }

        &.recipes {
            min-height: 8vw;

            .recipe-image {
                max-height: 8vw;
            }

            .recipe-name {
                background: linear-gradient(
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 1) 100%
                );
                font-size: 1.3vw;
                min-height: 1vw;
            }
        }
    }
}

@media screen and (max-width: 956px) {
    .body-wrapper {
        .header .flex-block {
            @include flexbox();
        }

        &.filter {
            .filter-content {
                border: 0;
                padding-right: 0;
            }

            .result-content {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    h3 {
        font-size: 1.5rem;
        letter-spacing: 1px;
    }

    .form-horizontal {
        label,
        input,
        select,
        .select,
        textarea {
            width: 100%;
        }

        label {
            margin-bottom: 1rem;
            margin-right: 0;
            text-align: left;
        }

        .form-group {
            display: block;
        }

        .form-group-inline label {
            margin-top: 1rem;
            text-align: left;
        }
    }

    .form-group-inline {
        width: 100%;
        @include flex-direction(column);
    }

    .flex-block {
        display: block;
    }

    .hero-wrapper {
        background-size: 100%;
        @include align-items(center);

        .recipe-link {
            background-color: transparent;
            background-position: right center;
            bottom: auto;
            padding-left: 0;
            padding-right: 0;
            position: relative;
        }

        .hero-block {
            min-height: auto;
            padding-bottom: 4%;
            padding-right: 4%;
            padding-left: 4%;
            text-align: center;
            width: 100%;

            h1,
            h2 {
                padding-bottom: 0.5rem;
            }

            .search-wrapper {
                background: rgba($color-sandy, 0.15);
                margin: 1rem 0;

                input {
                    font-size: 1.5rem;
                    padding: 1.5rem;
                }
            }
        }
    }

    .body-wrapper {
        &.column-narrow {
            max-width: none;
        }

        .column-block-25,
        .column-block-50,
        .column-block-75 {
            display: block;
            padding: 3% 0;
            width: 100%;
        }

        .block-title {
            h3 {
                max-width: 70%;
            }

            a {
                font-size: 1.3rem;
                letter-spacing: 1px;
            }
        }

        .featured-wrapper .featured-block {
            &.flavors {
                padding: 1rem;

                .flavor-image {
                    max-height: 10rem;
                }
            }

            .recipe-image {
                height: 15rem;
            }

            .flavor-name {
                font-size: 1.8rem;
            }
        }

        .sauce-container {
            @include justify-content(center);
        }

        .table-container {
            display: block;
            width: 100%;
        }
    }
}

@media screen and (max-width: 480px) {
    h1 {
        font-size: 3.7rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    .p-block {
        h2 {
            line-height: 2.8rem;
        }
    }

    ol.alpha {
        font-size: 2.5rem;
    }

    .hero-wrapper .promo {
        font-size: 1.2rem;
        height: 9.5rem;
        padding: 0 0.5rem;
        width: 14rem;
    }

    .body-wrapper {
        .column-block-25,
        .column-block-50,
        .column-block-75 {
            padding: 5% 0;
        }

        &.filter {
            .column-block-25,
            .column-block-75 {
                padding: 0;
            }

            div.header {
                border: 0;
                margin-bottom: 0;
            }
        }

        .featured-wrapper .featured-block.recipes {
            width: 100%;
        }
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.modal-wrapper {
    padding-top: 5%;
}

.modal-content {
    padding-top: 0.8%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 1%;
}

@media screen and (max-width: 320px) {
    .body-wrapper {
        .recipe-name {
            font-size: 1.4rem;
        }
    }
}

@media print {
    .no-print {
        display: none;
    }

    @page {
        margin: 1rem 0;
    }

    h1 {
        font-size: 3rem;
        margin-bottom: 0;
    }

    h2 {
        font-size: 2.4rem;
    }

    p {
        display: block;
        page-break-inside: avoid;
    }

    .page {
        margin-top: 5rem;
    }

    .recipe-details .recipe-container {
        min-height: auto;
    }

    .body-wrapper .table-container {
        margin-bottom: 0;
    }

    .recipe-details .recipe-ingredients {
        margin-top: 0;
    }

    .recipe-details .recipe-directions img {
        display: block;
        height: 10vw;
        page-break-inside: avoid;
    }
    
    .social-links {
      display: none;
    }
}
