@import '../../variables';

header {
  background-color: $color-white;
  position: fixed;
  top: 1.6rem;
  left: 1.6rem;
  transition: all 0.3s;
  width: calc(100% - 3.2rem);
  z-index: 100;

  &.wide {
    border-bottom: 1px solid $color-light-grey;
    top: 0;
    left: 0;
    width: 100%;
  }

  .header-wrapper {
    padding: 1rem 4%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
  }

  .logo {
    display: block;
    cursor: pointer;
    width: 10rem;
    height: 5rem;
    margin-right: 1.5rem;
    background-image: url("/images/kens_logo.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .mobile {
    .menu-btn {
      background: transparent;
      border: 0;
      cursor: pointer;
      width: 2.8rem;
      padding: 0;
      @include flexbox();
      @include flex-direction(column);

      span {
        background: $color-secondary;
        display: block;
        height: 0.2rem;
        margin: 0.2rem 0;
        width: 2.8rem;
        @include transition;
      }

      &:hover span {
        background: $color-primary;
        margin: 0.3rem 0;
      }
    }

    .close-btn {
      background: transparent;
      border: 0;
      position: absolute;
      right: 2rem;
      top: 2rem;
      height: 3rem;
      width: 3rem;
      padding: 0;
      @include transition;

      span {
        background: $color-white;
        display: block;
        height: 0.2rem;
        width: 100%;
        position: absolute;
        @include transform(rotate(45deg));

        &:last-child {
          @include transform(rotate(-45deg));
        }
      }

      &:hover {
        @include transform(rotate(90deg));

        span {
          background: $color-sandy;
        }
      }
    }

    .mobile-nav-wrapper {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      max-width: 80vw;
      height: 100vh;
      padding: 5% 10%;
      color: $color-white;
      background-color: $color-primary;
      overflow-y: auto;
      z-index: 100;
      @include transition;
      @include transform(translateX(150%));

      &.visible {
        @include transform(translateX(0));
      }
    }
  }
}

@media screen and (max-width: 849px) {
    header {
      .header-wrapper {
        display: block;
      }

      .logo {
        float: left;
      }

      .mobile .menu-btn {
        float: right;
        margin-top: 1.6rem;
      }
    }
}
