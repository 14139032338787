@import '../../variables';

footer {
  width: 100%;
  padding: 4%;

  .promo-wrapper {
    width: 100%;
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);

    .promo-recipes {
      h2 {
        font-size: 2.8rem;
        margin-bottom: 1rem;
      }

      a {
        color: $color-secondary;
        font-family: $font-serif;
        font-size: 2rem;
        font-style: italic;
        font-weight: lighter;

        &:hover {
          color: $color-primary;
        }

        .arrow-link {
          height: 2.5rem;
          margin-bottom: -0.5rem;
          width: 2.5rem;
        }

        span {
          white-space: nowrap;
        }
      }
    }

    .promo-join {
      background-color: $color-secondary;
      border-radius: 0.5rem;
      color: $color-white;
      margin: 0 1rem 0 6rem;
      max-width: 35rem;
      padding: 1rem 1rem 1rem 10rem;
      position: relative;

      h4 {
        color: $color-white;
        font-size: 3.2rem;
        margin: 0;
        text-transform: uppercase;
      }

      span {
        font-family: $font-sans-serif;
        font-size: 1.3rem;

        a {
          color: $color-white;
          font-size: 1.4rem;
          font-weight: 700;
          letter-spacing: 1px;
          margin-left: 0.4rem;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      img {
        position: absolute;
        left: -5rem;
        top: -1.2rem;
        width: 15rem;
      }
    }

    .sign-form {
      margin-left: 1rem;

      h4 {
        font-family: $font-sans-serif;
        font-weight: 700;
        text-transform: uppercase;
      }

      .form-group-inline {
        width: 100%;

        input {
          margin-right: 1rem;
        }
      }
    }
  }

  nav {
    @include flexbox();
    @include flex-wrap(wrap);

    &.footer-nav {

      a {
        color: $color-secondary;
        letter-spacing: 1px;
        line-height: 1.7;
        margin-left: 2rem;
        @include transition;

        &:hover {
          color: $color-primary;
        }
      }
    }

    &.social-nav {
      margin-bottom: 0.5rem;
      a {
        margin-right: 1rem;

        img {
          height: 1.5rem;
          width: 1.5rem;
          @include transition;
        }

        &:hover img {
          -webkit-filter: brightness(0);
          filter: brightness(0);
        }
      }
    }
  }

  .footer-wrapper {
    color: $color-grey;
    font-family: $font-sans-serif;
    font-size: 1rem;
    letter-spacing: 0.83px;
    margin-top: 10rem;
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(flex-end);

    .legal {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;

        a {
          color: $color-grey;
          font-size: 1rem;
          font-weight: 400;
          margin-left: 2rem;
          text-transform: uppercase;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  footer .promo-wrapper {
    @include flex-wrap(wrap);

    .promo-join {
      margin-right: 0;
    }

    .sign-form {
      margin-left: 0;

      .form-group-inline {
        @include flex-direction(row);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  footer .promo-wrapper {
    @include flex-direction(column);
    @include align-items(center);

    .promo-join {
      margin-top: 4rem;
    }

    .sign-form {
      margin-top: 5rem;
    }
  }
}

@media screen and (max-width: 480px) {
  footer {
    .promo-wrapper {
      .promo-join {
        margin-left: 2rem;
        max-width: none;

        h4 {
          font-size: 2.8rem;
        }

        span a {
          margin-left: 0;
          margin-top: 0.5rem;
          display: block;
        }
  
        img {
          left: -2rem;
          top: -1.6rem;
          width: 12rem;
        }
      }

      .sign-form .form-group-inline button {
        padding: 1.5rem;
      }
    }

    nav {
      display: block;

      &.social-nav {
        a {
          margin-right: 3rem;

          img {
            height: 3rem;
            width: 3rem;
          }
        }
        
      }

      &.footer-nav {
        margin-bottom: 3rem;
        @include order(1);

        a {
          font-size: 1.5rem;
          display: block;
          line-height: 2.5;
          margin-left: 0;
        }
      }
    }

    .footer-wrapper {
      font-size: 1.3rem;
      margin-top: 5rem;
      @include align-items(flex-start);
      @include flex-direction(column);

      & > div {
        @include order(2);
      }

      .legal {
        margin: 1rem 0;

        li {
          display: block;
          a {
            font-size: 1.3rem;
            margin-left: 0;

            &:last-child {
              margin-left: 2rem;
            }
          }
        }
      }
    }
  }
}
