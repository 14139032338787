@import url(https://use.typekit.net/hvf3znj.css);
@import url(https://use.typekit.net/hvf3znj.css);
@import url(https://use.typekit.net/hvf3znj.css);
@import url(https://use.typekit.net/hvf3znj.css);
/* Fonts */
/* Colors */
/* Elements */
/* Mixins */
nav a {
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase; }

.desktop nav a {
  display: inline-block;
  margin-right: 2rem; }
  .desktop nav a:last-child {
    margin-right: 0; }
  .desktop nav a.active {
    border-bottom: 1px solid #e2e2e2;
    border-top: 1px solid #e2e2e2;
    color: #9c6f1c;
    padding: 1rem 0; }

.mobile nav {
  margin-top: 3rem; }
  .mobile nav a {
    color: #ffffff;
    display: block;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 2rem 0;
    text-align: left; }
    .mobile nav a:hover {
      color: #e7dda7; }

@media screen and (max-width: 1000px) {
  .desktop nav a {
    padding: 0.5rem; } }

/* Fonts */
/* Colors */
/* Elements */
/* Mixins */
header {
  background-color: #ffffff;
  position: fixed;
  top: 1.6rem;
  left: 1.6rem;
  transition: all 0.3s;
  width: calc(100% - 3.2rem);
  z-index: 100; }
  header.wide {
    border-bottom: 1px solid #e2e2e2;
    top: 0;
    left: 0;
    width: 100%; }
  header .header-wrapper {
    padding: 1rem 4%;
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
  header .logo {
    display: block;
    cursor: pointer;
    width: 10rem;
    height: 5rem;
    margin-right: 1.5rem;
    background-image: url("/images/kens_logo.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
  header .mobile .menu-btn {
    background: transparent;
    border: 0;
    cursor: pointer;
    width: 2.8rem;
    padding: 0;
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    header .mobile .menu-btn span {
      background: #9c6f1c;
      display: block;
      height: 0.2rem;
      margin: 0.2rem 0;
      width: 2.8rem;
      transition: all 0.3s ease-in-out; }
    header .mobile .menu-btn:hover span {
      background: #062139;
      margin: 0.3rem 0; }
  header .mobile .close-btn {
    background: transparent;
    border: 0;
    position: absolute;
    right: 2rem;
    top: 2rem;
    height: 3rem;
    width: 3rem;
    padding: 0;
    transition: all 0.3s ease-in-out; }
    header .mobile .close-btn span {
      background: #ffffff;
      display: block;
      height: 0.2rem;
      width: 100%;
      position: absolute;
      transform: rotate(45deg); }
      header .mobile .close-btn span:last-child {
        transform: rotate(-45deg); }
    header .mobile .close-btn:hover {
      transform: rotate(90deg); }
      header .mobile .close-btn:hover span {
        background: #e7dda7; }
  header .mobile .mobile-nav-wrapper {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 80vw;
    height: 100vh;
    padding: 5% 10%;
    color: #ffffff;
    background-color: #062139;
    overflow-y: auto;
    z-index: 100;
    transition: all 0.3s ease-in-out;
    transform: translateX(150%); }
    header .mobile .mobile-nav-wrapper.visible {
      transform: translateX(0); }

@media screen and (max-width: 849px) {
  header .header-wrapper {
    display: block; }
  header .logo {
    float: left; }
  header .mobile .menu-btn {
    float: right;
    margin-top: 1.6rem; } }

/* Fonts */
/* Colors */
/* Elements */
/* Mixins */
footer {
  width: 100%;
  padding: 4%; }
  footer .promo-wrapper {
    width: 100%;
    display: flex;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    footer .promo-wrapper .promo-recipes h2 {
      font-size: 2.8rem;
      margin-bottom: 1rem; }
    footer .promo-wrapper .promo-recipes a {
      color: #9c6f1c;
      font-family: "Gentium Basic", serif;
      font-size: 2rem;
      font-style: italic;
      font-weight: lighter; }
      footer .promo-wrapper .promo-recipes a:hover {
        color: #062139; }
      footer .promo-wrapper .promo-recipes a .arrow-link {
        height: 2.5rem;
        margin-bottom: -0.5rem;
        width: 2.5rem; }
      footer .promo-wrapper .promo-recipes a span {
        white-space: nowrap; }
    footer .promo-wrapper .promo-join {
      background-color: #9c6f1c;
      border-radius: 0.5rem;
      color: #ffffff;
      margin: 0 1rem 0 6rem;
      max-width: 35rem;
      padding: 1rem 1rem 1rem 10rem;
      position: relative; }
      footer .promo-wrapper .promo-join h4 {
        color: #ffffff;
        font-size: 3.2rem;
        margin: 0;
        text-transform: uppercase; }
      footer .promo-wrapper .promo-join span {
        font-family: "Open Sans", sans-serif;
        font-size: 1.3rem; }
        footer .promo-wrapper .promo-join span a {
          color: #ffffff;
          font-size: 1.4rem;
          font-weight: 700;
          letter-spacing: 1px;
          margin-left: 0.4rem;
          text-decoration: underline; }
          footer .promo-wrapper .promo-join span a:hover {
            text-decoration: none; }
      footer .promo-wrapper .promo-join img {
        position: absolute;
        left: -5rem;
        top: -1.2rem;
        width: 15rem; }
    footer .promo-wrapper .sign-form {
      margin-left: 1rem; }
      footer .promo-wrapper .sign-form h4 {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        text-transform: uppercase; }
      footer .promo-wrapper .sign-form .form-group-inline {
        width: 100%; }
        footer .promo-wrapper .sign-form .form-group-inline input {
          margin-right: 1rem; }
  footer nav {
    display: flex;
    flex-wrap: wrap; }
    footer nav.footer-nav a {
      color: #9c6f1c;
      letter-spacing: 1px;
      line-height: 1.7;
      margin-left: 2rem;
      transition: all 0.3s ease-in-out; }
      footer nav.footer-nav a:hover {
        color: #062139; }
    footer nav.social-nav {
      margin-bottom: 0.5rem; }
      footer nav.social-nav a {
        margin-right: 1rem; }
        footer nav.social-nav a img {
          height: 1.5rem;
          width: 1.5rem;
          transition: all 0.3s ease-in-out; }
        footer nav.social-nav a:hover img {
          -webkit-filter: brightness(0);
          filter: brightness(0); }
  footer .footer-wrapper {
    color: #646466;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    letter-spacing: 0.83px;
    margin-top: 10rem;
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end; }
    footer .footer-wrapper .legal {
      list-style: none;
      margin: 0;
      padding: 0; }
      footer .footer-wrapper .legal li {
        display: inline-block; }
        footer .footer-wrapper .legal li a {
          color: #646466;
          font-size: 1rem;
          font-weight: 400;
          margin-left: 2rem;
          text-transform: uppercase; }
          footer .footer-wrapper .legal li a:hover {
            text-decoration: underline; }

@media screen and (max-width: 1200px) {
  footer .promo-wrapper {
    flex-wrap: wrap; }
    footer .promo-wrapper .promo-join {
      margin-right: 0; }
    footer .promo-wrapper .sign-form {
      margin-left: 0; }
      footer .promo-wrapper .sign-form .form-group-inline {
        -moz-flex-direction: row;
        flex-direction: row; } }

@media screen and (max-width: 900px) {
  footer .promo-wrapper {
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    footer .promo-wrapper .promo-join {
      margin-top: 4rem; }
    footer .promo-wrapper .sign-form {
      margin-top: 5rem; } }

@media screen and (max-width: 480px) {
  footer .promo-wrapper .promo-join {
    margin-left: 2rem;
    max-width: none; }
    footer .promo-wrapper .promo-join h4 {
      font-size: 2.8rem; }
    footer .promo-wrapper .promo-join span a {
      margin-left: 0;
      margin-top: 0.5rem;
      display: block; }
    footer .promo-wrapper .promo-join img {
      left: -2rem;
      top: -1.6rem;
      width: 12rem; }
  footer .promo-wrapper .sign-form .form-group-inline button {
    padding: 1.5rem; }
  footer nav {
    display: block; }
    footer nav.social-nav a {
      margin-right: 3rem; }
      footer nav.social-nav a img {
        height: 3rem;
        width: 3rem; }
    footer nav.footer-nav {
      margin-bottom: 3rem;
      order: 1; }
      footer nav.footer-nav a {
        font-size: 1.5rem;
        display: block;
        line-height: 2.5;
        margin-left: 0; }
  footer .footer-wrapper {
    font-size: 1.3rem;
    margin-top: 5rem;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -moz-flex-direction: column;
    flex-direction: column; }
    footer .footer-wrapper > div {
      order: 2; }
    footer .footer-wrapper .legal {
      margin: 1rem 0; }
      footer .footer-wrapper .legal li {
        display: block; }
        footer .footer-wrapper .legal li a {
          font-size: 1.3rem;
          margin-left: 0; }
          footer .footer-wrapper .legal li a:last-child {
            margin-left: 2rem; } }

/* Fonts */
/* Colors */
/* Elements */
/* Mixins */
html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  background-color: #ffffff;
  color: #062139;
  font-family: "Gentium Basic", serif;
  font-size: 1.8rem;
  font-weight: 400;
  width: 100%; }

h1,
h2 {
  font-weight: 700;
  width: 100%; }

h1 {
  font-size: 4.8rem;
  margin-bottom: 2rem; }

h2 {
  font-size: 3.4rem;
  margin-bottom: 1rem; }

h3 {
  color: #062139;
  font-size: 1.3vw;
  letter-spacing: 2px;
  text-transform: uppercase; }

h4 {
  color: #062139;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem; }

p,
.p-block {
  line-height: 1.4;
  width: 100%; }

p {
  margin-bottom: 1rem; }

.p-block {
  margin-bottom: 2rem; }
  .p-block h2 {
    line-height: 3.8rem; }

strong {
  font-weight: 700; }

i {
  font-style: italic; }

small {
  font-size: 80%; }

ul {
  line-height: 2;
  list-style: disc;
  margin-bottom: 1rem;
  padding-left: 4rem; }

ol {
  list-style-type: decimal;
  padding-left: 6rem; }
  ol li {
    margin-bottom: 2rem; }

ol.alpha {
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 1;
  list-style-type: lower-alpha; }
  ol.alpha p {
    font-size: 1.8rem;
    font-weight: 400; }

a {
  color: #062139;
  text-decoration: none;
  transition: all 0.3s ease-in-out; }
  a:hover {
    color: #9c6f1c; }
  a:focus {
    outline: none; }

img {
  image-rendering: -webkit-optimize-contrast; }

.arrow-link {
  height: 1.5rem;
  margin-left: 0.5rem;
  width: 1.5rem; }

button,
.button {
  background: transparent;
  border: 1px solid #9c6f1c;
  border-radius: 0.5rem;
  color: #646466;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  padding: 1.5rem 3rem;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out; }
  button:hover,
  .button:hover {
    background: #9c6f1c;
    color: #ffffff; }

.clearfix {
  clear: both;
  height: 0; }

form {
  width: 100%; }

label {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: uppercase; }

input,
select,
.select,
textarea {
  border: 1px solid #e2e2e2;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  padding: 1.5rem;
  width: 100%; }

textarea {
  overflow-y: auto; }

.select {
  height: 5.1rem;
  padding: 0;
  position: relative;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }
  .select .select__control {
    border: 0;
    cursor: pointer;
    height: 100%;
    width: 100%; }
  .select .select__menu {
    left: 0; }
  .select .select__value-container {
    height: 100%; }
  .select .select__indicator-separator {
    display: none; }

.form-group {
  margin-bottom: 3rem; }

.form-group-inline {
  width: 80%;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }
  .form-group-inline input,
  .form-group-inline select,
  .form-group-inline .select {
    width: 100%; }

.form-horizontal {
  display: flex;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  -moz-flex-direction: column;
  flex-direction: column; }
  .form-horizontal .form-group {
    width: 100%;
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
  .form-horizontal label {
    margin-bottom: 0;
    margin-right: 1rem;
    text-align: right;
    width: 20%; }
  .form-horizontal input,
  .form-horizontal select,
  .form-horizontal .select,
  .form-horizontal textarea {
    width: 80%; }
  .form-horizontal .form-group-inline label {
    margin-left: 1rem;
    text-align: center; }

.img-responsive {
  display: block;
  height: auto;
  width: 100%; }

.horizontal-line {
  border-bottom: 1px solid #e2e2e2;
  margin: 2rem 0;
  width: 100%; }

.flex-block {
  width: 100%;
  display: flex; }

.page {
  margin-top: 10rem;
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }

main,
.main {
  height: 100%;
  width: 100%; }

.video-frame {
  min-height: 50vw;
  width: 800px; }
  @media (orientation: landscape) {
    .video-frame {
      max-height: 90vh;
      min-height: 40vw; } }

.hero-wrapper {
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -10rem;
  padding: 0;
  position: relative;
  width: 100%;
  display: flex;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -moz-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .hero-wrapper .recipe-link {
    background-color: rgba(6, 33, 57, 0.3);
    bottom: 2rem;
    color: #ffffff;
    display: block;
    font-family: "Gentium Basic", serif;
    font-size: 1.8rem;
    font-style: italic;
    line-height: 1.2;
    padding: 1rem;
    padding-right: 2rem;
    position: absolute;
    right: 0;
    text-align: right;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end; }
    .hero-wrapper .recipe-link .arrow-link {
      height: 2.5rem;
      margin-left: 1rem;
      width: 2.5rem; }
    .hero-wrapper .recipe-link small {
      display: block; }
    .hero-wrapper .recipe-link:hover {
      color: #e7dda7; }
  .hero-wrapper .hero-block {
    height: 100%;
    min-height: 33rem;
    padding-bottom: 4%;
    padding-left: 4%;
    width: 50%; }
    .hero-wrapper .hero-block h1,
    .hero-wrapper .hero-block h2,
    .hero-wrapper .hero-block h3,
    .hero-wrapper .hero-block p {
      color: #ffffff; }
    .hero-wrapper .hero-block button,
    .hero-wrapper .hero-block .button {
      border-color: #ffffff;
      color: #ffffff; }
      .hero-wrapper .hero-block button:hover,
      .hero-wrapper .hero-block .button:hover {
        background: #ffffff;
        color: #062139; }
    .hero-wrapper .hero-block .search-wrapper {
      background: linear-gradient(to right, rgba(231, 221, 167, 0.15) 50%, rgba(231, 221, 167, 0) 100%);
      border: 0;
      font-family: "Open Sans", sans-serif;
      margin: 3vw 0; }
      .hero-wrapper .hero-block .search-wrapper input {
        color: #e7dda7;
        font-size: 1vw;
        padding: 1.3vw; }
        .hero-wrapper .hero-block .search-wrapper input::-webkit-input-placeholder {
          color: #e7dda7; }
        .hero-wrapper .hero-block .search-wrapper input:-ms-input-placeholder {
          color: #e7dda7; }
        .hero-wrapper .hero-block .search-wrapper input::-ms-input-placeholder {
          color: #e7dda7; }
        .hero-wrapper .hero-block .search-wrapper input::placeholder {
          color: #e7dda7; }
  .hero-wrapper .promo {
    background-image: url("/images/promo.png");
    background-position: center center;
    background-size: cover;
    bottom: 0;
    font-family: "Gentium Basic", serif;
    font-size: 1.8rem;
    font-weight: 700;
    height: 15.6rem;
    left: 0;
    padding: 0 1.5rem;
    position: absolute;
    width: 22.9rem;
    display: flex;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end; }

.body-wrapper {
  padding: 2% 4%;
  width: 100%;
  display: flex;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap; }
  .body-wrapper.column {
    -moz-flex-direction: column;
    flex-direction: column; }
  .body-wrapper.column-narrow {
    margin: auto;
    max-width: 70vw; }
  .body-wrapper .column-block-25,
  .body-wrapper .column-block-50,
  .body-wrapper .column-block-75 {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
  .body-wrapper .column-block-25 {
    width: 23%; }
  .body-wrapper .column-block-50 {
    width: 48%; }
  .body-wrapper .column-block-75 {
    width: 75%; }
  .body-wrapper .block-title {
    font-family: "Open Sans", sans-serif;
    width: 100%;
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .body-wrapper .block-title a {
      font-size: 1.1vw;
      letter-spacing: 2px;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
  .body-wrapper .featured-wrapper {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    flex-wrap: wrap; }
    .body-wrapper .featured-wrapper .featured-block {
      margin-top: 1.4rem;
      width: 49%; }
      .body-wrapper .featured-wrapper .featured-block.flavors {
        background-color: #e2e2e2;
        padding: 2rem;
        text-align: center; }
        .body-wrapper .featured-wrapper .featured-block.flavors .flavor-image {
          max-height: 18rem;
          transition: all 0.3s ease-in-out; }
      .body-wrapper .featured-wrapper .featured-block.recipes .recipe-name {
        background: linear-gradient(rgba(0, 0, 0, 0) 30%, black 100%); }
      .body-wrapper .featured-wrapper .featured-block.recipes .recipe-image {
        overflow: hidden;
        position: relative;
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center; }
        .body-wrapper .featured-wrapper .featured-block.recipes .recipe-image img {
          transition: all 0.3s ease-in-out;
          object-fit: cover;
          width: 100%; }
      .body-wrapper .featured-wrapper .featured-block .flavor-name {
        color: #646466;
        font-family: "Gentium Basic", serif;
        font-weight: 700;
        font-size: 2rem;
        text-align: center;
        text-transform: capitalize;
        transition: all 0.3s ease-in-out; }
      .body-wrapper .featured-wrapper .featured-block:hover .flavor-image,
      .body-wrapper .featured-wrapper .featured-block:hover .recipe-image img {
        transform: scale(1.1); }
      .body-wrapper .featured-wrapper .featured-block:hover .recipe-image img {
        -webkit-filter: brightness(1.3);
                filter: brightness(1.3); }
      .body-wrapper .featured-wrapper .featured-block:hover .flavor-name {
        color: #9c6f1c; }
  .body-wrapper .recipe-name {
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, black 100%);
    bottom: 0;
    color: #ffffff;
    font-size: 1.8rem;
    font-weight: 700;
    left: 0;
    line-height: 1.3;
    min-height: 1rem;
    padding: 3rem 1rem 1rem;
    position: absolute;
    text-shadow: 0 0 1.5rem black;
    text-transform: capitalize;
    width: 100%;
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end; }
    .body-wrapper .recipe-name .cooking-time {
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
      .body-wrapper .recipe-name .cooking-time .recipe-clock {
        background-image: url("/images/timer.png");
        background-repeat: no-repeat;
        height: 14px;
        width: 23px; }
  .body-wrapper.filter .column-block-25,
  .body-wrapper.filter .column-block-75 {
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1; }
  .body-wrapper.filter .column-block-25 {
    min-width: 22rem; }
  .body-wrapper.filter .filter-content {
    border-right: 1px solid #e2e2e2;
    padding-right: 2rem; }
  .body-wrapper.filter .result-content {
    padding-left: 2rem;
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    .body-wrapper.filter .result-content .load-more {
      margin: 0 auto;
      margin-top: 2rem;
      width: 15rem; }
  .body-wrapper .filter-toggle {
    background: url("/images/filter.png") no-repeat center center;
    cursor: pointer;
    height: 48px;
    width: 48px; }
  .body-wrapper .filter-collapse {
    display: none;
    margin-top: 2rem; }
    .body-wrapper .filter-collapse.show {
      display: block; }
  .body-wrapper .header {
    border-bottom: 1px solid #e2e2e2;
    font-family: "Open Sans", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .body-wrapper .header .flex-block {
      width: auto;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
    .body-wrapper .header .clear-all {
      border: 0;
      color: #062139;
      font-family: "Gentium Basic", serif;
      font-size: 1.3rem;
      font-weight: 700;
      padding: 0;
      text-transform: none;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between; }
      .body-wrapper .header .clear-all:hover {
        background-color: transparent;
        color: #9c6f1c; }
  .body-wrapper .results-header {
    border-bottom: 1px solid #e2e2e2;
    color: #646466;
    display: block;
    padding-bottom: 0.5rem; }
  .body-wrapper .sauce-container {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap; }
  .body-wrapper .table-container {
    margin-bottom: 2rem; }
    .body-wrapper .table-container .sause-table {
      color: #646466;
      font-size: 1.6rem;
      height: 3.5rem;
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
      .body-wrapper .table-container .sause-table .label {
        font-family: "Open Sans", sans-serif;
        font-size: 1.2rem;
        letter-spacing: 2px; }
      .body-wrapper .table-container .sause-table :nth-child(odd) {
        height: 2rem; }
      .body-wrapper .table-container .sause-table .underline {
        border-bottom: 2px dotted #e2e2e2;
        margin: 0.5rem 0.5rem 0 0.5rem;
        -moz-flex-grow: 1;
        -ms-flex-grow: 1;
        flex-grow: 1; }
      .body-wrapper .table-container .sause-table .info-list {
        list-style: none;
        padding: 0; }
        .body-wrapper .table-container .sause-table .info-list li {
          display: inline-block;
          padding-left: 0.5rem; }
          .body-wrapper .table-container .sause-table .info-list li:first-child {
            padding-left: 0; }
          .body-wrapper .table-container .sause-table .info-list li::after {
            content: ','; }
          .body-wrapper .table-container .sause-table .info-list li:last-child::after {
            content: none; }
      .body-wrapper .table-container .sause-table .sause-link {
        color: #062139;
        cursor: pointer;
        text-align: right;
        display: flex;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        transition: all 0.3s ease-in-out; }
        .body-wrapper .table-container .sause-table .sause-link:hover {
          color: #9c6f1c; }
  .body-wrapper .loading {
    padding: 1rem;
    margin: 0;
    text-align: center; }

@media screen and (min-width: 769px) {
  .body-wrapper .featured-wrapper .featured-block {
    margin-top: 1vw; }
    .body-wrapper .featured-wrapper .featured-block.flavors {
      min-height: 17vw;
      overflow: hidden;
      padding: 1vw; }
      .body-wrapper .featured-wrapper .featured-block.flavors .flavor-image {
        max-height: 12vw; }
      .body-wrapper .featured-wrapper .featured-block.flavors .flavor-name {
        font-size: 1.3vw; }
    .body-wrapper .featured-wrapper .featured-block.recipes {
      min-height: 8vw; }
      .body-wrapper .featured-wrapper .featured-block.recipes .recipe-image {
        max-height: 8vw; }
      .body-wrapper .featured-wrapper .featured-block.recipes .recipe-name {
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, black 100%);
        font-size: 1.3vw;
        min-height: 1vw; } }

@media screen and (max-width: 956px) {
  .body-wrapper .header .flex-block {
    display: flex; }
  .body-wrapper.filter .filter-content {
    border: 0;
    padding-right: 0; }
  .body-wrapper.filter .result-content {
    padding: 0; } }

@media screen and (max-width: 768px) {
  h3 {
    font-size: 1.5rem;
    letter-spacing: 1px; }
  .form-horizontal label,
  .form-horizontal input,
  .form-horizontal select,
  .form-horizontal .select,
  .form-horizontal textarea {
    width: 100%; }
  .form-horizontal label {
    margin-bottom: 1rem;
    margin-right: 0;
    text-align: left; }
  .form-horizontal .form-group {
    display: block; }
  .form-horizontal .form-group-inline label {
    margin-top: 1rem;
    text-align: left; }
  .form-group-inline {
    width: 100%;
    -moz-flex-direction: column;
    flex-direction: column; }
  .flex-block {
    display: block; }
  .hero-wrapper {
    background-size: 100%;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .hero-wrapper .recipe-link {
      background-color: transparent;
      background-position: right center;
      bottom: auto;
      padding-left: 0;
      padding-right: 0;
      position: relative; }
    .hero-wrapper .hero-block {
      min-height: auto;
      padding-bottom: 4%;
      padding-right: 4%;
      padding-left: 4%;
      text-align: center;
      width: 100%; }
      .hero-wrapper .hero-block h1,
      .hero-wrapper .hero-block h2 {
        padding-bottom: 0.5rem; }
      .hero-wrapper .hero-block .search-wrapper {
        background: rgba(231, 221, 167, 0.15);
        margin: 1rem 0; }
        .hero-wrapper .hero-block .search-wrapper input {
          font-size: 1.5rem;
          padding: 1.5rem; }
  .body-wrapper.column-narrow {
    max-width: none; }
  .body-wrapper .column-block-25,
  .body-wrapper .column-block-50,
  .body-wrapper .column-block-75 {
    display: block;
    padding: 3% 0;
    width: 100%; }
  .body-wrapper .block-title h3 {
    max-width: 70%; }
  .body-wrapper .block-title a {
    font-size: 1.3rem;
    letter-spacing: 1px; }
  .body-wrapper .featured-wrapper .featured-block.flavors {
    padding: 1rem; }
    .body-wrapper .featured-wrapper .featured-block.flavors .flavor-image {
      max-height: 10rem; }
  .body-wrapper .featured-wrapper .featured-block .recipe-image {
    height: 15rem; }
  .body-wrapper .featured-wrapper .featured-block .flavor-name {
    font-size: 1.8rem; }
  .body-wrapper .sauce-container {
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .body-wrapper .table-container {
    display: block;
    width: 100%; } }

@media screen and (max-width: 480px) {
  h1 {
    font-size: 3.7rem; }
  h2 {
    font-size: 2.5rem; }
  .p-block h2 {
    line-height: 2.8rem; }
  ol.alpha {
    font-size: 2.5rem; }
  .hero-wrapper .promo {
    font-size: 1.2rem;
    height: 9.5rem;
    padding: 0 0.5rem;
    width: 14rem; }
  .body-wrapper .column-block-25,
  .body-wrapper .column-block-50,
  .body-wrapper .column-block-75 {
    padding: 5% 0; }
  .body-wrapper.filter .column-block-25,
  .body-wrapper.filter .column-block-75 {
    padding: 0; }
  .body-wrapper.filter div.header {
    border: 0;
    margin-bottom: 0; }
  .body-wrapper .featured-wrapper .featured-block.recipes {
    width: 100%; } }

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */ }

.modal-wrapper {
  padding-top: 5%; }

.modal-content {
  padding-top: 0.8%; }

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 1%; }

@media screen and (max-width: 320px) {
  .body-wrapper .recipe-name {
    font-size: 1.4rem; } }

@media print {
  .no-print {
    display: none; }
  @page {
    margin: 1rem 0; }
  h1 {
    font-size: 3rem;
    margin-bottom: 0; }
  h2 {
    font-size: 2.4rem; }
  p {
    display: block;
    page-break-inside: avoid; }
  .page {
    margin-top: 5rem; }
  .recipe-details .recipe-container {
    min-height: auto; }
  .body-wrapper .table-container {
    margin-bottom: 0; }
  .recipe-details .recipe-ingredients {
    margin-top: 0; }
  .recipe-details .recipe-directions img {
    display: block;
    height: 10vw;
    page-break-inside: avoid; }
  .social-links {
    display: none; } }

